<!--千万消费劵复制出来修改的页面-->
<template>
  <div class="intro">
    <van-nav-bar title="订单详情">
      <template #left>
        <van-icon :size="24" color="#141212" name="arrow-left" slot="left" @click="exit()" />
      </template>
    </van-nav-bar>
    <div class="content">
      <div class="od_out od_top">
        <div class="price d_f ali_c">
          <div class="title flex_1">{{getStatusText(info).name}}</div>
          <div class="price_num">
            <span class="jfdk" v-if="totalDiscountAmount>0">消费劵 抵扣{{totalDiscountAmount / 100}}</span>
            <span>￥{{money ? (money/100).toFixed(2) : 0}}</span>
          </div>
        </div>
        <div class="time d_f ali_c" v-if="getStatusText(info).num == 0">
          <img src="./img/od_clark.png" alt="">
          <template v-if="isCutDown">
            <span>支付剩余时间</span>
            <van-count-down class="time_num" :time="time" />
            <span v-if="isCutDown">请尽快支付</span>
          </template>
          <template v-else>
            <span v-if="getStatusText(info).num == 1">预订成功，祝您旅途愉快。</span>
            <span v-if="getStatusText(info).num == 2">游玩体验如何，立即前往评价，欢迎再次使用智游乐山。</span>
            <span v-if="getStatusText(info).num == 3 && info[0].closeType == 1">您的订单已取消，欢迎再次使用智游乐山。</span>
            <span v-if="getStatusText(info).num == 3 && info[0].closeType == 3">您的订单由于超时未支付已自动取消，欢迎再次使用智游乐山。</span>
            <span v-if="getStatusText(info).num == 6">系统出票失败，退款将在1~3个工作日内园路退回至您的账户。</span>
            <span v-if="getStatusText(info).num == 10">退款申请审核中，审核后将发送短信至您的手机，请注意查收。</span>
            <span v-if="getStatusText(info).num == 12">退款处理成功，已退回原支付账户。</span>
            <span v-if="getStatusText(info).num == 11">退款失败，请查看退款进度了解退款详情，若有疑问请联系客服。</span>
          </template>
        </div>
        <div v-else>
          <span></span>
        </div>
        <div class="od_detail">
          <div class="d_f ali_c">
            <span class="flex_1">订单编号：{{info[0].orderNumber}}</span>
            <img src="./img/od_copy.png" alt="" class="cur_p copy_btn" @click="doCopy" :data-clipboard-text="info[0].orderNumber">
          </div>
          <div class="d_f ali_c">
            <span class="flex_1" v-if="info[0].creatorTime">订单创建时间：{{info[0].creatorTime}}</span>
            <a href="javascript:;" @click="moneyDetail(info[0].orderNumber)">费用明细</a>
          </div>
        </div>
      </div>
      <div class="od_out od_tickets">
        <div class="head">
          <div class="title d_f ali_c">
            <span class="name_span">{{info[0].commoditytName}}</span>
            <div class="tag">{{(info[0].parentCommodityName ? info[0].parentCommodityName : info[0].commoditytName)+'X'+info.length}}</div>
          </div>
          <div>
            <span>使用日期：{{info[0].beginDate}}</span>
            <span class="ml_22">时间：{{info[0].timeSlot}}</span>
          </div>
        </div>
        <div class="odt_content">
          <!--<div class="d_f">
            <span>使用方法：</span>
            <span class="flex_1">{{}}</span>
          </div>-->
          <div class="d_f" style="margin-bottom: 10px">
            <span>入园地址：</span>
            <span class="flex_1">{{info[0].address}}</span>
          </div>
          <!--<div class="d_f">
            <span>入园时间：</span>
            <span class="flex_1">{{}}</span>
          </div>-->
          <div class="d_f" v-show="isRealName">
            <span><span class="del_three">出行人</span>：</span>
          </div>
          <div class="od_person" v-show="isRealName">
            <div class="od_p_card d_f" v-for="(item,index) in info" :key="index">
              <div class="tag">游客{{index+1}}</div>
              <div class="flex_1">
                <div class="name">{{item.visitorName}}</div>
                <div>{{item.certificateNumber}}</div>
              </div>
              <div class="od_status">{{getStatusText([item]).name}}</div>
            </div>
          </div>
        </div>
      </div>
      <!--出行人-->
      <div class="od_out od_contact">
        <div class="title">联系人信息</div>
        <div v-show="!isRealName">联系人姓名：{{reservationPersonName}}</div>
        <div>联系人手机：{{info[0].reservationPersonTelephone}}</div>
      </div>
      <!--消费劵-->
      <div class="od_out od_contact d_f ali_c j_s od_coupon"  v-if="totalDiscountAmount > 0">
        <div>消费劵</div>
        <div>抵扣 <span>￥{{totalDiscountAmount / 100}}</span></div>
      </div>
      <div class="od_out od_contact">
        <div class="title">退改规则</div>
        <div class="rules">1、1~3个工作日内完成退款审核。</div>
        <div class="rules">2、一笔订单多张票不支持部分退款。</div>
        <div class="rules">3、下单后不支持改期，可取消后重新预订。</div>
      </div>
    </div>
    <div class="od_btn p_f d_f ali_c fd_rr">
      <div class="od_btn_out cur_p" @click="cancelOrder" v-if="getStatusText(info).num == 0">取消</div>
      <div class="od_btn_out cur_p again" @click="gotoDetail">再次预订</div>
      <div class="od_btn_out cur_p" @click.stop="refundPay" v-if="btnRefund && info[0].sellPrice>0">申请退款</div>
      <div class="od_btn_out cur_p pay" @click="gotoPay" v-if="(getStatusText(info).num == 0)&&isCutDown">去支付</div>
    </div>
  </div>
</template>

<script>
Date.prototype.format = function () {
    var s = '';
    s += this.getFullYear() + '-'; // 获取年份。
    s += this.getMonth() + 1 + '-'; // 获取月份。
    s += this.getDate() + ' '; // 获取日。
    s += this.getHours() + ':'; // 获取小时。
    s += this.getMinutes() + ':'; // 获取分。
    s += this.getSeconds(); // 获取秒。
    return s; // 返回日期。
};
import { Dialog, Toast, Icon, NavBar, CountDown } from 'vant';
import QRCode from 'qrcodejs2'
export default {
    name: 'orderDetail',
    components: {
        'van-nav-bar': NavBar,
        'van-count-down': CountDown,
        'van-icon': Icon,
    },
    data() {
        return {
            apis: {
                detail: '/stlsOrder/findOrderDetail',
                refund: '/stlsOrder/refundApply',
                cancelOrder: '/stlsOrder/cancel',
                queryOrderList: '/stlsOrder/queryOrderList', //查询订单信息
            },
            code: this.$route.query.orderNumber,
            time: 0,
            isCutDown: true,
            info: [{}],
            orderType: 1,
            buyNum: 0,
            btnRefund: false,
            money: 0, // 总金额
            totalDiscountAmount: 0, // 所有人使用的积分抵扣金额
            isRealName: 1, // 是否实名制
            reservationPersonName: '', // 联系人姓名
        };
    },
    created() {
        this.queryOrderList(); // 查询是否实名认证
        this.queryDetail();
    },
    methods: {
        // 查询订单信息
        queryOrderList() {
            let me = this;
            me.$get({
                url: me.apis.queryOrderList,
                closeToast: true,
                params: {
                    orderNumber: me.code,
                    sortName: 'order_number',
                    token: me.$global.token,
                },
            }).then(res => {
                // console.log(res)
                if (res.data.length) {
                    me.isRealName = res.data[0].order.isRealName;
                    me.orderType = res.data[0].order.orderType;
                    me.reservationPersonName =
                        res.data[0].order.reservationPersonName;
                }
            });
        },
        //订单状态文字
        getStatusText(row) {
            // console.log(row)
            let statusArr = [
                { name: '待付款', color: 'green', num: 0 }, //1
                { name: '待使用', color: 'orange', num: 1 }, //2
                { name: '已使用', color: 'green', num: 2 }, //3
                { name: '已取消', color: 'dark', num: 3 }, //4
                { name: '已支付', color: 'dark', num: 4 }, //5
                { name: '出票中', color: 'dark', num: 5 }, //6
                { name: '出票失败', color: 'dark', num: 6 }, //7
                { name: '退款审核中', color: 'dark', num: 7 }, //8
                { name: '退票中', color: 'dark', num: 8 }, //9
                { name: '退票失败', color: 'dark', num: 9 }, //10
                { name: '退款中', color: 'dark', num: 10 }, //11
                { name: '退款失败', color: 'dark', num: 11 }, //12
                { name: '退款成功', color: 'dark', num: 12 }, //13
                { name: '财务审核中', color: 'dark', num: 13 }, //14
                { name: '审核失败', color: 'dark', num: 14 }, //15
            ];
            if (this.orderType == 4) {
                statusArr = [
                    {
                        name: '待付款',
                        color: 'green',
                        num: 0,
                        btnStatus: false,
                    }, //1
                    {
                        name: '预订成功',
                        color: 'orange',
                        num: 1,
                        btnStatus: false,
                    }, //2
                    {
                        name: '已使用',
                        color: 'green',
                        num: 2,
                        btnStatus: false,
                    }, //3
                    { name: '已取消', color: 'dark', num: 3, btnStatus: false }, //4
                    { name: '已支付', color: 'dark', num: 4, btnStatus: false }, //5
                    {
                        name: '订单审核中',
                        color: 'dark',
                        num: 5,
                        btnStatus: false,
                    }, //6
                    { name: '已取消', color: 'dark', num: 6, btnStatus: false }, //7
                    {
                        name: '退款审核中',
                        color: 'dark',
                        num: 7,
                        btnStatus: false,
                    }, //8
                    { name: '退票中', color: 'dark', num: 8, btnStatus: false }, //9
                    {
                        name: '退票失败',
                        color: 'dark',
                        num: 9,
                        btnStatus: false,
                    }, //10
                    {
                        name: '退款中',
                        color: 'dark',
                        num: 10,
                        btnStatus: false,
                    }, //11
                    {
                        name: '退款失败',
                        color: 'dark',
                        num: 11,
                        btnStatus: false,
                    }, //12
                    {
                        name: '退款成功',
                        color: 'dark',
                        num: 12,
                        btnStatus: false,
                    }, //13
                    {
                        name: '财务审核中',
                        color: 'dark',
                        num: 13,
                        btnStatus: false,
                    }, //14
                    {
                        name: '审核失败',
                        color: 'dark',
                        num: 14,
                        btnStatus: false,
                    }, //15
                ];
            }
            let num = 0;
            if (row.length > 0) {
                if (row[0].refundStatus == null) {
                    if (row[0].orderStatus == 0 || row[0].orderStatus == 1) {
                        num = 0;
                    }
                    if (
                        row[0].orderStatus == 7 &&
                        row[0].orderDetailStatus == 4
                    ) {
                        num = 2;
                    }
                    if (row[0].orderStatus == 2) {
                        num = 3;
                    }
                    if (row[0].orderStatus == 4) {
                        num = 4;
                    }
                    if (row[0].orderStatus == 5) {
                        num = 5;
                    }
                } else {
                    if (row[0].orderRefundStatus == 1) {
                        num = 7;
                    }
                    if (row[0].orderRefundStatus == 2) {
                        num = 8;
                    }
                    if (row[0].orderRefundStatus == 3) {
                        num = 9;
                    }
                    if (row[0].orderRefundStatus == 4) {
                        num = 10;
                    }
                    if (row[0].orderRefundStatus == 5) {
                        num = 11;
                    }
                    if (row[0].orderRefundStatus == 6) {
                        num = 12;
                    }
                    if (row[0].orderRefundStatus == 7) {
                        num = 13;
                    }
                    if (row[0].orderRefundStatus == 8) {
                        num = 14;
                    }
                }
                if (row[0].orderStatus == 6) {
                    num = 6;
                }
                row.map((item, index) => {
                    //有一张已使用就是已使用
                    if (item.orderStatus == 7 && item.orderDetailStatus == 4) {
                        num = 2;
                    }
                });
                row.map((item, index) => {
                    //已使用和退款失败的状态
                    if (item.orderStatus == 7 && item.orderDetailStatus == 4) {
                        row.map((it, idx) => {
                            if (
                                it.refundStatus != null &&
                                it.orderRefundStatus == 5
                            ) {
                                num = 1;
                            }
                        });
                    }
                });
                let btnStatus = 0;
                row.map((item, index) => {
                    //有一张待使用就是待使用
                    if (
                        item.orderStatus == 7 &&
                        (item.orderDetailStatus == 0 ||
                            item.orderDetailStatus == 1 ||
                            item.orderDetailStatus == 3)
                    ) {
                        num = 1;
                        btnStatus++;
                    }
                });
                if (btnStatus == row.length) {
                    this.btnRefund = true;
                }
                return statusArr[num];
            }
        },
        doCopy: function () {
            let _t = this;
            let clipboard = new _t.$Clipboard('.copy_btn'); //在main.js中引用
            clipboard.on('success', e => {
                // 释放内存
                Toast('复制成功');
                clipboard.destroy();
            });
            clipboard.on('error', e => {
                // 不支持复制
                Toast({
                    message: '该浏览器不支持自动复制',
                    type: 'warning',
                });
                // 释放内存
                clipboard.destroy();
            });
        },
        refundPay() {
            let me = this;

            Dialog.confirm({
                cancelButtonText: '我再想想',
                message: '确定申请退款吗？',
            })
                .then(() => {
                    me.$post({
                        url: me.apis.refund,
                        params: {
                            orderNumber: me.info[0].orderNumber,
                            token: me.$global.token,
                        },
                    }).then(res => {
                        Toast('退款成功');
                        me.info = [{}];
                        me.btnRefund = false;
                        me.queryDetail();
                    });
                })
                .catch(() => {});
        },
        //去支付
        gotoPay() {
            this.$router.push({
                path: '/payWay',
                query: {
                    price: this.info[0].sellPrice,
                    buyNum: this.info.length,
                    code: this.code,
                },
            });
        },
        //再次预定
        gotoDetail() {
            this.$router.push({
                path: '/',
            });
        },
        //取消订单
        cancelOrder() {
            let me = this;
            me.$get({
                url: me.apis.cancelOrder,
                params: {
                    orderId: me.info[0].orderId,
                    token: me.$global.token,
                },
            }).then(res => {
                Toast('取消成功');
                me.queryDetail();
            });
        },
        //查询详情
        queryDetail() {
            let me = this;
            me.$get({
                url: me.apis.detail,
                params: {
                    orderNumber: me.code,
                    token: me.$global.token,
                },
            }).then(res => {
                me.info = res;
                me.buyNum = me.info.length;
                let now = new Date().getTime();
                let order = new Date(
                    this.$renderTime(me.info[0].creatorTime)
                ).getTime();
                if (order + 15 * 60 * 1000 > now) {
                    me.time = order + 15 * 60 * 1000 - now;
                } else {
                    me.isCutDown = false;
                }
                me.money = 0;
                for (let i in res) {
                    me.money += res[i].sellPrice;
                }
                // 查询全部积分抵扣金额
                let totalDiscountAmount = 0;
                for (let i in res) {
                    totalDiscountAmount += res[i].discountAmount;
                }
                me.totalDiscountAmount = totalDiscountAmount;
                this.creatQrCode() // 二维码核销
            });
        },
        // number转大写
        toChinese(values) {
            let chin_list = [
                '零',
                '一',
                '二',
                '三',
                '四',
                '五',
                '六',
                '七',
                '八',
                '九',
                '十',
            ]; //所有的数值对应的汉字
            let sn = parseInt(values);
            return chin_list[sn];
        },
        exit() {
            this.$router.go(-1);
        },
        // 费用明细
        moneyDetail(orderNumber) {
            this.$router.push({
                path: '/moneyDetail',
                query: {
                    orderNumber: orderNumber,
                },
            });
        },
        // 二维码核销
        creatQrCode() {
          console.log(this.info[0].writeOffCode)
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: this.info[0].writeOffCode, // 需要转换为二维码的内容
            width: 100,
            height: 100,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })
    },
    },
};
</script>

<style scoped lang="scss">
.od_btn {
    .od_btn_out {
        height: 30px;
        line-height: 30px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(174, 174, 174, 1);
        border-radius: 15px;
        text-align: center;
        font-size: 14px;

        font-weight: 400;
        color: rgba(20, 18, 18, 1);
        padding: 0 20px;
        margin-left: 13px;
        &.again {
            /*margin-left: 13px;*/
        }
        &.pay {
            background: linear-gradient(
                90deg,
                rgba(146, 228, 96, 1),
                rgba(59, 211, 103, 1)
            );
            border-color: rgba(0, 0, 0, 0);
            color: white;
        }
    }
    width: 100%;
    height: 45px;
    bottom: 0;
    left: 0;
    background-color: white;
    padding: 0 20px;
    box-sizing: border-box;
    box-shadow: 0px -1px 2px 0px rgba(6, 0, 1, 0.05);
}
.od_coupon{
  font-size: 14px;
  div:last-child{
    font-weight: 400;
    span{
      color: #FF3E6E;
    }
  }
}
.od_contact {
    .rules {
        margin-top: 10px;
    }
    .title {
        font-size: 14px;

        font-weight: 500;
        color: rgba(20, 18, 18, 1);
        margin-bottom: 13px;
    }
    padding: 13px 8px 17px 8px;
}
.del_three {
    letter-spacing: 4px;
}
.od_person {
    .od_p_card {
        .od_status {
            width: 70px;
            line-height: 54px;
            text-align: center;
            background: rgba(237, 255, 241, 1);
            border-left: 1px solid rgba(207, 207, 207, 1);
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: rgba(51, 51, 51, 1);
        }
        .name {
            width: 60px;
            padding-top: 8px;
        }
        .tag {
            width: 39px;
            height: 16px;
            background: rgba(63, 212, 103, 1);
            border-radius: 2px;
            position: absolute;
            left: -1px;
            top: 0;
            color: white;
            line-height: 16px;
            text-align: center;
            font-size: 10px;

            font-weight: 400;
        }
        box-sizing: border-box;
        padding: 0 0 0 50px;
        margin: 12px 0 10px auto;
        width: 313px;
        height: 54px;
        line-height: 20px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(207, 207, 207, 1);
        border-radius: 2px;
        position: relative;
    }
    .title {
        font-size: 14px;

        font-weight: 500;
        color: rgba(20, 18, 18, 1);
    }
    padding: 0 0 0 8px;
}
.od_tickets {
    .odt_content {
        .d_f {
            margin-top: 11px;
        }
        padding: 0 14px 0 8px;
    }
    .head {
        .title {
            .name_span {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 50%;
                display: inline-block;
            }
            .tag {
                max-width: 50%;
                padding: 0 9px;
                height: 16px;
                background: rgba(63, 212, 103, 1);
                border-radius: 2px;
                margin-left: 7px;
                line-height: 16px;
                color: white;
                text-align: center;
                font-size: 10px;

                font-weight: 500;
            }
            font-size: 14px;

            font-weight: 500;
            color: rgba(20, 18, 18, 1);
            margin-bottom: 13px;
        }
        border-bottom: 1px solid #eeeeee;
        padding: 13px 0 9px 8px;
    }
}
.od_top {
    .od_detail .d_f {
        img {
            width: 16px;
        }
        height: 29px;
        padding: 0 11px 0 8px;
    }
    .time {
        .time_num {
            margin: 0 20px;
        }
        img {
            width: 17px;
            margin-right: 9px;
        }
        font-size: 12px;

        font-weight: 500;
        color: rgba(255, 74, 74, 1);
        height: 29px;
        background-color: #fef6f0;
        padding-left: 11px;
    }
    .price {
        .price_num {
            font-size: 16px;

            font-weight: bold;
            color: rgba(255, 62, 110, 1);
            .jfdk {
                border: 1px solid rgba(255, 62, 110, 1);
                border-radius: 15px;
                font-size: 10px;
                vertical-align: middle;
                margin-right: 10px;
                padding: 0 5px;
            }
        }
        height: 38px;
        padding: 0 11px 0 8px;
        font-size: 16px;

        font-weight: bold;
        color: rgba(20, 18, 18, 1);
    }
    .hx {
        padding: 0 11px 0 8px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(20, 18, 18, 1);
        img{
            display: block;
            width: 1000px ;
            margin: 0 auto!important;;
          }
        .qrcode{
          // 
          display: flex;
          justify-content: center;
         
        }
        p {
            line-height: 30px;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            &:last-of-type {
                font-size: 12px;
                color: rgb(108, 106, 106);
            }
        }
    }
}
.od_out {
    background: rgba(255, 255, 255, 1);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 12px;
}
.intro {
    font-size: 12px;
    font-weight: 400;
    color: #131212;
    height: 100%;
    background-color: #eeeeee;
    overflow: auto;
    .content {
        padding: 61px 15px 60px 15px;
    }
    .od_detail {
        a {
            color: #222;
            padding-right: 10px;
            background: url(../../../assets/img/icon-more.png) no-repeat right
                center/auto 10px #fff;
        }
    }
}
</style>
